import api from "../utils/api";

// Fetch all petugas
export const getAllPetugas = async () => {
  try {
    const response = await api.get("/petugas");
    return response.data;
  } catch (error) {
    console.error("Error fetching petugas", error);
    throw error;
  }
};

// Fetch a petugas by ID
export const getPetugasById = async (id) => {
  try {
    const response = await api.get(`/petugas/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching petugas by ID", error);
    throw error;
  }
};

// Create a new petugas
export const createPetugas = async (data) => {
  try {
    const response = await api.post("/petugas", data);
    return response.data;
  } catch (error) {
    console.error("Error creating petugas", error);
    throw error;
  }
};

// Update a petugas by ID
export const updatePetugas = async (id, data) => {
  try {
    const response = await api.put(`/petugas/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating petugas", error);
    throw error;
  }
};

// Delete a petugas by ID
export const deletePetugas = async (id) => {
  try {
    const response = await api.delete(`/petugas/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting petugas", error);
    throw error;
  }
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPeminjamanById } from "../services/peminjamanService";

const PeminjamanDetail = () => {
  const { id } = useParams();
  const [peminjaman, setPeminjaman] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPeminjaman = async () => {
      try {
        const data = await getPeminjamanById(id);
        setPeminjaman(data);
      } catch (error) {
        console.error("Error fetching peminjaman by ID:", error);
        setError("Failed to fetch peminjaman details.");
      } finally {
        setLoading(false);
      }
    };

    fetchPeminjaman();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      {peminjaman ? (
        <div>
          <h1 className="text-xl font-bold mb-4 mt-6">Peminjaman Detail</h1>
          <div className="mb-4 mt-6">
            <h2 className="text-xl font-semibold mb-4">General Information</h2>
            <p>
              <strong>Name:</strong> {peminjaman.name}
            </p>
            <p>
              <strong>Instansi:</strong> {peminjaman.instansi}
            </p>
            <p>
              <strong>No Surat:</strong> {peminjaman.nosurat}
            </p>
            <p>
              <strong>Tujuan:</strong> {peminjaman.tujuan}
            </p>
            <p>
              <strong>Status:</strong> {peminjaman.status}
            </p>
            <p>
              <strong>Tanggal Dikembalikan:</strong>{" "}
              {peminjaman.dikembalikan
                ? new Date(peminjaman.dikembalikan).toLocaleDateString()
                : "Belum Dikembalikan"}
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold">Barang</h2>
            {peminjaman.Barangs && peminjaman.Barangs.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Nama Barang</th>
                    <th>Jumlah</th>
                  </tr>
                </thead>
                <tbody>
                  {peminjaman.Barangs.map((item) => (
                    <tr key={item.id} className="mb-2">
                      <td>{item.name}</td>
                      <td>{item.PeminjamanBarang.jumlah}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No barang associated with this peminjaman.</p>
            )}
          </div>
        </div>
      ) : (
        <div>No peminjaman found.</div>
      )}
    </div>
  );
};

export default PeminjamanDetail;

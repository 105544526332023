import React, { useState, useEffect } from "react";
import {
  getAllPetugas,
  createPetugas,
  updatePetugas,
  deletePetugas,
} from "../services/petugasService";
import Notification from "../components/Notification";
import PetugasList from "../components/PetugasList";
import {
  PlusIcon,
  PencilSquareIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/solid";

const PetugasPage = () => {
  const [petugas, setPetugas] = useState([]);
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [editId, setEditId] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetchPetugas();
  }, []);

  const fetchPetugas = async () => {
    try {
      const data = await getAllPetugas();
      setPetugas(data);
    } catch (error) {
      setError("Error fetching petugas");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId) {
        await updatePetugas(editId, form);
        setSuccess("Updated successfully!");
      } else {
        await createPetugas(form);
        setSuccess("Created successfully!");
      }
      fetchPetugas();
      resetForm();
    } catch (error) {
      setError("Error saving");
    }
  };

  const resetForm = () => {
    setForm({ name: "", email: "", password: "" });
    setEditId(null);
  };

  const handleEdit = (petugas) => {
    setForm({
      name: petugas.name,
      email: petugas.email,
      password: "", // Clear password field for security reasons
    });
    setEditId(petugas.id);
    setError("");
    setSuccess("");
  };

  const handleDelete = async (id) => {
    try {
      await deletePetugas(id);
      setSuccess("Petugas deleted successfully!");
      fetchPetugas();
    } catch (error) {
      setError("Error deleting petugas");
    }
  };

  // Handle add button click
  const handleAdd = () => {
    resetForm();
    setEditId(null);
    setIsAdding(true); // Set add mode
    setError("");
    setSuccess("");
  };

  // Handle cancel button click
  const handleCancel = () => {
    resetForm();
    setEditId(null);
    setIsAdding(false); // Reset add mode
  };

  const shouldShowPetugasList = !isAdding && !editId;

  return (
    <div>
      <h1 className="text-xl font-bold mb-4 mt-8">
        {editId ? "Update" : ""} Petugas
      </h1>
      {!isAdding && !editId && (
        <button onClick={handleAdd} className="btn btn-accent mb-4">
          <PlusIcon className="h-5 w-5" /> Add
        </button>
      )}
      <Notification message={error} type="error" />
      <Notification message={success} type="success" />

      {(editId || isAdding) && (
        <form onSubmit={handleSubmit} className="form-control gap-2">
          <input
            className="input input-bordered"
            type="text"
            name="name"
            placeholder="Name"
            value={form.name}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            className="input input-bordered"
            placeholder="Email"
            value={form.email}
            onChange={handleInputChange}
            required
          />
          <input
            type="password"
            name="password"
            className="input input-bordered"
            placeholder="Password"
            value={form.password}
            onChange={handleInputChange}
          />
          <button className="btn btn-accent" type="submit">
            <PencilSquareIcon className="h-5 w-5" />
            {editId ? "Update" : "Save"}
          </button>
          <button type="button" className="btn" onClick={handleCancel}>
            <ArrowUturnLeftIcon className="h-5 w-5" />
            Cancel
          </button>
        </form>
      )}

      {shouldShowPetugasList && (
        <PetugasList
          petugas={petugas}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default PetugasPage;

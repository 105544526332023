// src/components/InventarisList.js
import React from "react";

const InventarisList = ({ barang }) => {
  // Periksa apakah ada barang
  if (!barang || barang.length === 0) {
    return <p>Tidak ada barang yang ditemukan.</p>;
  }

  return (
    <div>
      {/* <h2>Daftar Barang</h2> */}
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Nama</th>
              <th>Kode</th>
              <th>Kategori</th>
              <th>Merk</th>
              <th>Stok</th>
            </tr>
          </thead>
          <tbody>
            {barang.map((b) => (
              <tr key={b.id}>
                <td>{b.name}</td>
                <td>{b.code}</td>
                <td>{b.category}</td>
                <td>{b.brand}</td>
                <td>{b.stock}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InventarisList;

import axios from "axios";

// Ambil base URL dari variabel lingkungan
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getAllInventaris = async () => {
  try {
    const response = await api.get("/inventaris");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching barang",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

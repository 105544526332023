// src/components/PetugasList.js
import React from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";

const PetugasList = ({ petugas, onEdit, onDelete }) => {
  return (
    <div>
      {/* <h2>Daftar Petugas</h2> */}
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {petugas.map((petugas) => (
              <tr key={petugas.id}>
                <td>{petugas.name}</td>
                <td>{petugas.email}</td>
                <td className="flex gap-2">
                  <button
                    className="btn btn-warning"
                    onClick={() => onEdit(petugas)}
                  >
                    <PencilSquareIcon className="h-4 w-4" />
                  </button>
                  <button
                    className="btn btn-error"
                    onClick={() => onDelete(petugas.id)}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PetugasList;

import api from "../utils/api";

// Fetch all barang
export const getAllBarang = async () => {
  try {
    const response = await api.get("/barang");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching barang",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Fetch a barang by ID
export const getBarangById = async (id) => {
  try {
    const response = await api.get(`/barang/${id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching barang by ID",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Create a new barang
export const createBarang = async (data) => {
  try {
    const response = await api.post("/barang", data);
    return response.data;
  } catch (error) {
    console.error(
      "Error creating barang",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Update a barang by ID
export const updateBarang = async (id, data) => {
  try {
    const response = await api.put(`/barang/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Error updating barang",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Delete a barang by ID
export const deleteBarang = async (id) => {
  try {
    const response = await api.delete(`/barang/${id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting barang",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

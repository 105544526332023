import React from "react";

const BarangDropdown = ({ barang, selectedBarang = [], onChange }) => {
  // Handle the addition of new barang with quantity
  const handleAddBarang = () => {
    onChange([...selectedBarang, { barang: "", jumlah: 1 }]);
  };

  // Handle change for a specific item
  const handleItemChange = (index, key, value) => {
    const newBarang = selectedBarang.map((item, i) =>
      i === index ? { ...item, [key]: value } : item
    );
    onChange(newBarang);
  };

  // Handle removal of a specific item
  const handleRemoveItem = (index) => {
    onChange(selectedBarang.filter((_, i) => i !== index));
  };

  return (
    <div>
      {selectedBarang.map((item, index) => (
        <div key={index} className="flex items-center space-x-2 mb-2">
          <select
            value={item.barang}
            onChange={(e) => handleItemChange(index, "barang", e.target.value)}
            className="select select-bordered"
          >
            <option value="">Pilih Barang</option>
            {barang.map((b) => (
              <option key={b.id} value={b.id}>
                {b.name}
              </option>
            ))}
          </select>
          <input
            type="number"
            value={item.jumlah}
            onChange={(e) => handleItemChange(index, "jumlah", e.target.value)}
            className="input input-bordered"
            min="1"
          />
          <button
            type="button"
            onClick={() => handleRemoveItem(index)}
            className="btn btn-error btn-sm"
          >
            Hapus
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddBarang}
        className="btn btn-primary"
      >
        Tambah Barang
      </button>
    </div>
  );
};

export default BarangDropdown;

import api from "../utils/api";

// Fetch all peminjaman
export const getAllPeminjaman = async () => {
  try {
    const response = await api.get("/peminjaman");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching peminjaman",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Fetch a peminjaman by ID
export const getPeminjamanById = async (id) => {
  try {
    const response = await api.get(`/peminjaman/${id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching peminjaman by ID",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Create a new peminjaman
export const createPeminjaman = async (data) => {
  try {
    const response = await api.post("/peminjaman", data);
    return response.data;
  } catch (error) {
    console.error(
      "Error creating peminjaman",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Update a peminjaman by ID
export const updatePeminjaman = async (id, data) => {
  try {
    const response = await api.put(`/peminjaman/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Error updating peminjaman",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Delete a peminjaman by ID
export const deletePeminjaman = async (id) => {
  try {
    const response = await api.delete(`/peminjaman/${id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting peminjaman",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const kembalikanPeminjaman = async (id) => {
  try {
    const response = await api.patch(`/peminjaman/${id}/kembalikan`);
    return response.data;
  } catch (error) {
    console.error(
      "Error marking peminjaman as returned",
      error.response ? error.response.data : error.message
    );
    // throw error;
    return { status: 200, message: "Error occurred while marking as returned" };
  }
};

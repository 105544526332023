import React, { useState } from "react";
import { login } from "../services/authService"; // Import dari authService
import Notification from "../components/Notification";
import { LockClosedIcon } from "@heroicons/react/24/solid";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      // Redirect to another page or update UI
      // redirect("/dashboard");
      window.location.href = "/"; // Atau menggunakan react-router: history.push('/dashboard')
    } catch (error) {
      setError("Invalid credentials");
    }
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4 mt-8">Login</h2>
      <Notification message={error} type="error" />
      <form onSubmit={handleLogin} className="form-control gap-2">
        <input
          type="email"
          placeholder="Email"
          value={email}
          className="input input-bordered"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          className="input input-bordered"
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button className="btn btn-neutral" type="submit">
          <LockClosedIcon className="w-5 h-5 mr-2" />
          Login
        </button>
      </form>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
};

export default LoginPage;

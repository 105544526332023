import React from "react";
import {
  ArchiveBoxArrowDownIcon,
  InboxStackIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/solid";
import diskominfo from "../assets/diskominfo.png";
import pemkot from "../assets/pemkot.png";

const DashboardPage = () => {
  return (
    <>
      <div className="flex flex-row justify-center mb-8 mt-8 gap-2">
        <img src={diskominfo} alt="Diskominfo Manado" className="h-10" />
        <img src={pemkot} alt="Diskominfo Manado" className="h-10" />
      </div>
      <h2 className="text-2xl flex justify-center mb-4">
        Sistem Peminjaman Inventaris
      </h2>
      <p className="text-center">
        Selamat datang di sistem peminjaman inventaris Dinas Komunikasi dan
        Informatika Kota Manado, berikut langkah pengajuan peminjaman.
      </p>
      <div className="flex flex-row gap-4">
        <div className="card bg-base-100 w-96 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">1</h2>
            <InboxStackIcon className="h-10 w-10" />
            <p>
              Sebelum meminjam barang, pihak yang ingin meminjam diharapkan
              memeriksa ketersediaan barang yang akan dipinjam dengan memilih
              menu <span className="font-bold">Inventaris</span> yang terdapat
              pada navigasi
            </p>
          </div>
        </div>

        <div className="card bg-base-100 w-96 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">2</h2>
            <ArchiveBoxIcon className="h-10 w-10" />
            <p>
              Setelah memastikan ketersediaan barang yang ingin dipinjam, pihak
              yang ingin meminjam barang diwajibkan membawa Surat Perintah Tugas
              (SPT) yang berisi nama instansi peminjam, nama orang yang
              melakukan peminjaman, dan rincian barang yang akan dipinjam di
              Diskominfo Kota Manado.
            </p>
          </div>
        </div>

        <div className="card bg-base-100 w-96 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">3</h2>
            <ArchiveBoxArrowDownIcon className="h-10 w-10" />
            <p>
              Setelah admin sistem peminjaman inventaris memastikan kredibilatas
              Surat Perintah Tugas (SPT) yang diberikan pihak peminjam, barulah
              pihak peminjam dapat mengambil barang yang ingin dipinjam.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;

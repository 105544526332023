// src/pages/InventarisPage.js
import React, { useEffect, useState } from "react";
import { getAllInventaris } from "../services/inventarisService";
import InventarisList from "../components/InventarisList";

const InventarisPage = () => {
  const [barang, setBarang] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Ambil data barang saat komponen dimuat
  useEffect(() => {
    const fetchBarang = async () => {
      try {
        const data = await getAllInventaris();
        setBarang(data);
      } catch (error) {
        setError("Error mengambil data barang.");
        console.error("Error fetching barang:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBarang();
  }, []);

  if (loading) return <p>Memuat...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h1 className="text-xl font-bold mb-4 mt-8">Daftar Inventaris Barang</h1>
      <InventarisList barang={barang} />
    </div>
  );
};

export default InventarisPage;

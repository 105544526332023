import React, { useEffect, useState } from "react";
import {
  getAllBarang,
  createBarang,
  updateBarang,
  deleteBarang,
} from "../services/barangService";
import BarangList from "../components/BarangList";
import {
  PlusIcon,
  PencilSquareIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/solid";

const BarangPage = () => {
  const [barang, setBarang] = useState([]);
  const [editingBarang, setEditingBarang] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [form, setForm] = useState({
    name: "",
    code: "",
    category: "",
    brand: "",
    stock: 0,
  });
  const [image, setImage] = useState(null);

  // Fetch barang data on component mount
  useEffect(() => {
    const fetchBarang = async () => {
      try {
        const data = await getAllBarang();
        console.log("Fetched barang:", data);
        setBarang(data);
      } catch (error) {
        console.error("Error fetching barang:", error);
      }
    };
    fetchBarang();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  // Handle image change
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("name", form.name);
    formData.append("code", form.code);
    formData.append("category", form.category);
    formData.append("brand", form.brand);
    formData.append("stock", form.stock);

    if (image) {
      formData.append("image", image);
    }

    try {
      if (editingBarang) {
        await updateBarang(editingBarang.id, formData);
        console.log("Updated barang:", formData);
      } else {
        await createBarang(formData);
        console.log("Created barang:", formData);
      }

      resetForm();
      const data = await getAllBarang(); // Fetch updated data after create/update
      console.log("Updated barang list after create/update:", data);
      setBarang(data); // Update state barang
    } catch (error) {
      console.error("Error saving barang:", error);
    }
  };

  // Set form for editing
  const handleEdit = (barang) => {
    console.log("Editing barang:", barang);
    setForm(barang);
    setEditingBarang(barang);
    setIsAdding(false);
    setImage(null);
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await deleteBarang(id);
      console.log("Deleted barang with ID:", id);
      setBarang(barang.filter((b) => b.id !== id));
    } catch (error) {
      console.error("Error deleting barang:", error);
    }
  };

  const resetForm = () => {
    setForm({ name: "", code: "", category: "", brand: "", stock: 0 });
    setEditingBarang(null);
    setImage(null);
  };

  // Handle add button click
  const handleAdd = () => {
    resetForm();
    setIsAdding(true);
  };

  // Handle cancel button click
  const handleCancel = () => {
    resetForm();
    setIsAdding(false);
  };

  const shouldShowBarangList = !isAdding && !editingBarang;

  return (
    <div>
      <h1 className="text-xl font-bold mb-4 mt-8">
        {editingBarang ? "Update" : ""} Inventaris
      </h1>
      {!isAdding && !editingBarang && (
        <button onClick={handleAdd} className="btn btn-accent mb-4">
          <PlusIcon className="h-5 w-5" /> Add
        </button>
      )}

      {(editingBarang || isAdding) && (
        <form onSubmit={handleSubmit} className="form-control gap-2">
          <input
            type="text"
            name="name"
            value={form.name}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Name"
            required
          />
          <input
            type="text"
            name="code"
            value={form.code}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Code"
            required
          />
          <input
            type="text"
            name="category"
            value={form.category}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Category"
            required
          />
          <input
            type="text"
            name="brand"
            value={form.brand}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Brand"
            required
          />
          <input
            type="number"
            name="stock"
            value={form.stock}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Stock"
            required
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="input input-bordered"
          />
          <button className="btn btn-accent" type="submit">
            <PencilSquareIcon className="w-4 h-4 mr-2" />
            {editingBarang ? "Update" : "Save"}
          </button>
          <button type="button" className="btn" onClick={handleCancel}>
            <ArrowUturnLeftIcon className="w-4 h-4 mr-2" />
            Cancel
          </button>
        </form>
      )}

      {shouldShowBarangList && (
        <BarangList
          barang={barang}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default BarangPage;

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL; // Sesuaikan dengan URL API Anda

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, {
      email,
      password,
    });
    localStorage.setItem("token", response.data.token);
    return response.data;
  } catch (error) {
    console.error("Login error", error);
    throw error;
  }
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const logout = () => {
  // Hapus token dari localStorage
  localStorage.removeItem("token");
  // Redirect ke halaman login
  window.location.href = "/login"; // Sesuaikan dengan rute login Anda
};

// src/components/Navbar.js
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import diskominfo from "../assets/diskominfo.png";

const Navbar = () => {
  const navigate = useNavigate();

  const isAuthenticated = () => {
    // Periksa apakah token ada di localStorage
    return !!localStorage.getItem("token");
  };

  const handleLogout = () => {
    // Hapus token dari localStorage dan arahkan ke halaman login
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="navbar bg-base-300">
      <div className="flex-1 flex items-center">
        <Link to="/" className="btn btn-ghost text-xl">
          <img src={diskominfo} alt="Diskominfo Manado" className="h-8" />
          <div className="text-sm text-left flex flex-col">
            <span className="font-bold">Dinas Komunikasi dan Informatika</span>
            <span className="text-xs">Pemerintah Kota Manado</span>
          </div>
        </Link>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal gap-2">
          {!isAuthenticated() && (
            <>
              <li>
                <Link to="/inventaris">Inventaris</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
              </li>
            </>
          )}
          {isAuthenticated() && (
            <>
              <li>
                <Link to="/barang">Inventaris</Link>
              </li>
              <li>
                <Link to="/peminjaman">Peminjaman</Link>
              </li>
              <li>
                <Link to="/petugas">Petugas</Link>
              </li>
              <li>
                <button onClick={handleLogout}>Logout</button>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;

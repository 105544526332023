// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BarangPage from "./pages/BarangPage";
import PeminjamanPage from "./pages/PeminjamanPage";
import PetugasPage from "./pages/PetugasPage";
import LoginPage from "./pages/LoginPage";
import "./input.css";
import Navbar from "./components/Navbar";
import DashboardPage from "./pages/DashboardPage";
import PeminjamanDetail from "./components/PeminjamanDetail";
import Footer from "./components/Footer";
import InventarisPage from "./pages/InventarisPage";

function App() {
  return (
    <Router>
      <Navbar />
      <p>&nbsp;</p>
      <div className="container mx-auto">
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/petugas" element={<PetugasPage />} />
          <Route path="/barang" element={<BarangPage />} />
          <Route path="/inventaris" element={<InventarisPage />} />
          <Route path="/peminjaman" element={<PeminjamanPage />} />
          <Route path="/peminjaman/:id" element={<PeminjamanDetail />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
